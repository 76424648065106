import clsx from 'clsx';

interface IButtonGroupProps {
  options: { label: string; value: string }[],
  setSelected: any;
  selected: string;
  className: string;
}

const Button = ({ option, selected, setSelected, type }) => (
  <button
    type="button"
    className={clsx(
      "inline-flex relative focus:z-10 items-center py-2 px-4 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 border border-gray-300 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500",
      { "rounded-l-md": type === 'left' },
      { "rounded-r-md": type === 'right' },
      { "border-primary-500 ring-1 ring-primary-500": selected === option.value  }
    )}
    onClick={() => setSelected(option.value)}
  >
    {option.label}
  </button>
);

export function ButtonGroup({
  options,
  selected,
  setSelected,
  className,
}: IButtonGroupProps) {
  return (
    <span className={className}>
      {options.length === 1 && (
        <Button
          option={options[0]}
          selected={selected}
          setSelected={setSelected}
          type="middle"
        />
      )}

      {options.length === 2 && (
        <>
          <Button
            option={options[0]}
            selected={selected}
            setSelected={setSelected}
            type="left"
          />
          <Button
            option={options[1]}
            selected={selected}
            setSelected={setSelected}
            type="right"
          />
        </>
      )}

      {options.length > 2 && (
        <>
          <Button
            option={options[0]}
            selected={selected}
            setSelected={setSelected}
            type="left"
          />
          {options.filter((o, i) => {
            if (i === 0) return;
            if (i === options.length - 1) return;

            return <Button
              option={o}
              selected={selected}
              setSelected={setSelected}
              type="middle"
            />
          })}
          <Button
            option={options.length - 1}
            selected={selected}
            setSelected={setSelected}
            type="right"
          />
        </>

      )}
    </span>
  );
}

export default ButtonGroup;
