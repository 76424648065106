import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";

export interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  fullWidth?: boolean;
  children: JSX.Element | JSX.Element[];
}

export const Modal: React.FC<IModalProps> = ({
  isOpen,
  onClose,
  children,
  fullWidth = false,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
        open={isOpen}
      >
        <div data-testid="modal" className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                /* eslint-disable tailwindcss/migration-from-tailwind-2 */
                clsx(
                  "inline-block p-6 my-8 w-full text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform",
                  { "max-w-md": !fullWidth },
                  { "max-w-3xl": fullWidth }
                )
                /* eslint-enable tailwindcss/migration-from-tailwind-2 */
              }
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
