const Pagination = ({
  currentPage,
  itemsPerPage,
  totalCount,
  setPage,
  totalPages
}) => {
  if (totalPages === 1) {
    return;
  }

  const rangeStart = (currentPage * itemsPerPage) - itemsPerPage + 1;
  const rangeEnd =
    currentPage * itemsPerPage < totalCount ? currentPage * itemsPerPage : totalCount;

  return (
    <nav
      className="flex items-center justify-between py-3 bg-white border-gray-200"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{rangeStart}</span> to <span className="font-medium">{rangeEnd}</span> of{' '}
          <span className="font-medium">{totalCount}</span> results
        </p>
      </div>
      <div className="flex justify-between flex-1 sm:justify-end">
        {currentPage > 1 && (
          <>
            <a
              onClick={() => setPage(1)}
              className="relative inline-flex items-center px-4 py-2 mr-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 rounded-md"
            >
              First
            </a>
            <a
              onClick={() => setPage(currentPage - 1)}
              className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 rounded-md"
            >
              Previous
            </a>
          </>
        )}
        {currentPage < totalPages && (
          <>
            <a
              onClick={() => setPage(currentPage + 1)}
              className="relative inline-flex items-center px-4 py-2 ml-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 rounded-md"
            >
              Next
            </a>
            <a
              onClick={() => setPage(totalPages)}
              className="relative inline-flex items-center px-4 py-2 ml-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 rounded-md"
            >
              Last
            </a>
          </>
        )}
      </div>
    </nav>
  )
}

export default Pagination;
