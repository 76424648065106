import clsx from 'clsx';
import { CheckIcon, ChevronUpDownIcon  } from '@heroicons/react/24/solid'
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { useField } from 'formik';

export function SelectDropdown({ error, name, options, label, required = true }) {
  const [field, _, helpers] = useField({ name });

  return (
    <div className="flex flex-col">
      <Listbox value={field.value} onChange={(value: string) => helpers.setValue(value)}>
        {({ open }) => (
          <>
            <Listbox.Label className="block mb-2 text-base font-semibold">{label} {!!required && <span className="text-red-500">*</span>}</Listbox.Label>
            <div className="relative mt-1">
              <Listbox.Button className="relative flex items-center content-center justify-between flex-1 w-full px-4 py-3 bg-white border border-gray-200 outline-none rounded-md focus:border-primary-500 focus:outline-none transition-all duration-200">
                <span className="block truncate">{field.value || label}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 w-full py-1 overflow-auto text-base bg-white shadow-lg max-h-60 rounded-md focus:outline-none ring-1 ring-black ring-opacity-5 sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        clsx(
                          { 'text-white bg-gray-600': active },
                          { 'text-gray-900': !active },
                          'relative py-2 pr-9 pl-3 cursor-default select-none'
                        )
                      }
                      value={option.name}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={clsx(
                            { 'font-semibold': selected },
                            { 'font-normal': !selected },
                            'block truncate'
                            )}>
                            {option.name}
                          </span>

                          {selected ? (
                            <span
                              className={clsx(
                                { 'text-white': active },
                                { 'text-gray-600': !active },
                                'flex absolute inset-y-0 right-0 items-center pr-4'
                              )}
                            >
                              <CheckIcon className="w-5 h-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {!!error && (
        <p className="pb-2 text-sm text-red-500 capitalize">{error}</p>
      )}
    </div>
  )
}
