interface ISingleCardProps {
  item: { name: string; stat: string };
}

export function SingleCard({
  item,
}: ISingleCardProps) {
  return (
    <div className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
      <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
      <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
    </div>
  );
}
