import InputMask from 'react-input-mask';
import clsx from 'clsx';
import { InputHTMLAttributes } from 'react';

interface InputProps
  extends React.DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  rightIcon?: React.ReactNode;
  error?: string;
  label?: string;
  htmlFor?: string;
  touched?: boolean;
}

export function YearInput({
  error,
  label,
  htmlFor,
  className,
  rightIcon,
  required = true,
  touched,
  ...props
}: InputProps) {
  return (
    <div className="flex flex-col">
      {!!label && (
        <label htmlFor={htmlFor} className="block mb-2 text-base font-semibold">
          {label} {!!required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className="flex relative flex-1 justify-between content-center items-center py-1 px-4 bg-white rounded-md border border-gray-200 focus:border-primary-500 transition-all duration-200">
        <InputMask
          mask="9999"
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
          required={required}
          className={clsx(
            'flex-1 py-2 w-full outline-none focus:outline-none',
            className
          )}
        />
        {!!rightIcon && rightIcon}
      </div>

      {!!error && !!touched && (
        <p className="pb-2 text-sm text-red-500 capitalize">{error}</p>
      )}
    </div>
  );
}
