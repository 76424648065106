import clsx from 'clsx';
import React, { TextareaHTMLAttributes } from 'react';

export interface TextAreaProps
  extends React.DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  error?: string;
}

export function TextArea({ error, className, ...props }: TextAreaProps) {
  return (
    <div className="flex flex-col">
      <div className="flex relative flex-1">
        <textarea
          {...props}
          className={clsx(
            'flex-1 py-3 px-4 bg-white rounded-md border border-gray-200 focus:border-primary-500 outline-none focus:outline-none transition-all duration-200',
            className
          )}
        />
      </div>
      {!!error && (
        <p className="pb-2 text-sm text-red-500 capitalize ">{error}</p>
      )}
    </div>
  );
}
