import clsx from 'clsx';
import { InputHTMLAttributes } from 'react';
import { DebounceInput as DBInput } from 'react-debounce-input';

interface DebouncedInputProps
  extends React.DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  rightIcon?: React.ReactNode;
  error?: string;
  label?: string;
  htmlFor?: string;
  touched?: boolean;
}

export function DebouncedInput({
  className,
  error,
  htmlFor,
  label,
  minLength = 2,
  required = true,
  rightIcon,
  touched,
  ...props
}: DebouncedInputProps) {
  return (
    <div className="flex flex-col">
      {!!label && (
        <label htmlFor={htmlFor} className="block mb-2 text-base font-semibold">
          {label} {!!required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className="relative flex items-center content-center justify-between flex-1 px-4 py-1 bg-white border border-gray-200 rounded-md focus:border-primary-500 transition-all duration-200">
        {/* @ts-ignore: No overload matches this call */}
        <DBInput //
          {...props}
          required={required}
          className={clsx(
            'flex-1 py-2 w-full border-none outline-none focus:border-none focus:ring-0 focus:outline-none',
            className
          )}
        />
        {!!rightIcon && rightIcon}
      </div>

      {!!error && !!touched && (
        <p className="pb-2 text-sm text-red-500 capitalize">{error}</p>
      )}
    </div>
  );
}
