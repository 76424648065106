import clsx from "clsx";

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  loading?: boolean;
}

export function Button({
  className,
  children,
  loading = false,
  ...props
}: ButtonProps) {
  return (
    <button
      {...props}
      role="button"
      className={clsx(
        "flex flex-1 justify-center items-center py-3 px-4 w-full text-sm font-semibold tracking-widest text-center text-white bg-red-500 rounded-md focus:ring focus:ring-primary-200 transition-all duration-200 cursor-pointer",
        {
          "bg-gray-400 cursor-not-allowed": props.disabled,
          "hover:bg-primary-500": !props.disabled,
        },
        className
      )}
    >
      {loading && (
        <span className="w-6 h-6 ml-3 mr-2 border-white rounded-full border-y-2 animate-spin"></span>
      )}
      {children}
    </button>
  );
}
