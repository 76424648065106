interface ITableCardProps {
  item: { name: string; stat: [{name: string; count: number;}] };
}

export function TableCard({
  item,
}: ITableCardProps) {
  return (
    <div className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
      <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
      <dd className="mt-1">
        {item.stat.map((stat) => (
          <div className="flex flex-row justify-between" key={stat.name}>
            <span className="text-sm font-semibold text-right">{stat.name}: </span><span>{stat.count}</span>
          </div>
        ))}
      </dd>
    </div>
  );
}
